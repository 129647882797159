<template>
    <v-dialog v-model="show" persistent width="600">
      <v-card :loading="isLoading">
        <v-card-title class="headline">{{
          "Tải dữ liệu bằng tệp tin excel"
        }}</v-card-title>
        <br />
        <v-card-text>
          <v-btn 
            class="mb-2" 
            outlined 
            block 
            @click="handleDownloadTemplate()"
            :loading="loadingDownloadTemplate"
          >
            <v-icon class="mr-1">mdi-cloud-download</v-icon>
            Tải file mẫu
          </v-btn>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <UploadFile 
                v-model="form.file" 
                ></UploadFile>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
          <v-btn
            :disabled="!form.file"
            :loading="isLoading"
            color="primary"
            @click="importSanPhamExcel"
          >
            <v-icon left>mdi-cloud-upload</v-icon>
            Tải lên
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script>
  import UploadFile from "@/components/upload/UploadFile";
  import chatThaiAPI from "@/api/sx.chatthai.api";
  import { saveAs } from "file-saver";
  export default {
    components: {
      UploadFile,
    },
    data: () => ({
      loadingDownloadTemplate: false,
      isLoading: false,
      show: false,
      importResponseData: {},
      form: {
        file: null,
      },
    }),
    watch: {
      show(val) {
        if (!val) {
          this.form = this.$options.data.call(this).form;
        }
      },
    },
    methods: {
      async handleDownloadTemplate() {
        try {
          this.loadingDownloadTemplate = true;
          const res = await chatThaiAPI.productTemplate();
          if (res && res.isDone) {
            saveAs(new Blob([res.data]), "Mau-nhap-du-lieu-san-pham.xlsx");
          }
        } finally {
          this.loadingDownloadTemplate = false;
        }
      },
      showFormImportExcel() {
        this.show = true;
        if (this.$refs["files"]) {
          this.$refs["files"].value = null;
        }
      },
      async importSanPhamExcel() {
        try {
            this.isLoading = true;
            const selectedFile = this.form.file[0].file;
            if (!selectedFile) {
                return;
            }
            const formData = new FormData();
            formData.append("file", selectedFile);
            const res = await chatThaiAPI.productImport(formData);
            this.importResponseData ={...res.data.data};
            this.$emit("on-done", this.importResponseData);
        } catch (error) {
            this.$emit("on-error");
        } finally {
            this.show=false;
        }
      },
    },
  };
  </script>
  