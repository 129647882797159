<template>
    <v-dialog
      v-model="show" 
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-h5 mb-1">
          THÔNG TIN IMPORT EXCEL
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              class="pa-1"
              cols="12"
              sm="12"
            >
              <v-card
                elevation="1"
              >
                <v-card-title>
                  <span class="text-body-2 font-weight-medium">Tổng số dòng trong file Excel</span>
                </v-card-title>
                <v-card-text class="text-h3 font-weight-bold">
                  <span>{{ importResponseData.sourceRowCount }}</span>
                </v-card-text>  
              </v-card>
            </v-col>
            <v-col
              class="pa-1"
              cols="12"
              sm="6"
            >
              <v-card
                color="green lighten-3"
                height="100%"
              >
                <v-card-title>
                  <span class="text-body-2 font-weight-medium">Số dòng import thành công</span>
                </v-card-title>
                <v-card-text class="text-h3 font-weight-bold">
                  <span>{{ importResponseData.importedRowCount }}</span>
                </v-card-text>  
              </v-card>
            </v-col>
            <v-col
              class="pa-1"
              cols="12"
              sm="6"
            >
              <v-card
                color="red lighten-3"
                height="100%"
              >
                <v-card-title>
                  <span class="text-body-2 font-weight-medium">Số dòng không import được</span>
                </v-card-title>
                <v-card-text class="text-h3 font-weight-bold">
                  <span>{{ importResponseData.failedRowCount }}</span>
                </v-card-text>  
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            text
            @click="onCloseImportDialog"
          >
            Đóng
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary"
            @click="exportErrorExcel"
          >
            Chi tiết
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import chatThaiAPI from "@/api/sx.chatthai.api";
import { saveAs } from "file-saver";
export default {
    // props: ["importResponseData"],
    data() {
        return {
          show: false,
          importResponseData: {},
        }
    },
    methods: {
        openImportDialog(data) {
          this.importResponseData = data;
          this.show = true;
        },
        onCloseImportDialog() {
          this.show = false;
          this.$emit('onClose');
        },
        async exportErrorExcel() {
          let dateObj = new Date();
          let day = dateObj.getDate();
          let month = dateObj.getMonth() + 1;
          let year = dateObj.getFullYear();
          let currDate = day + "/" + month + "/" + year;
          const res = await chatThaiAPI.productExportError(this.importResponseData);
          if (res && res.isDone) {
            saveAs(new Blob([res.data]), "loi_import_san_pham" + "-" + currDate + ".xlsx");
          }
        },
    },
}
</script>