<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="800px"
    max-height="75vh"
    transition="dialog-transition"
  >
    <v-card :loading="isLoading">
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text class="my-4 overflow-auto">
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <div class="d-flex flex-column mx-3">
                <div class="label-form">Chọn sản phẩm</div>
                <v-autocomplete
                  v-model="form.selectedProductSX"
                  placeholder="Chọn sản phẩm"
                  :items="listProductSX"
                  outlined
                  :item-text="getText" 
                  item-value="id"
                  dense
                  return-object
                  hide-details="auto"
                  :rules="nameProductSXRules"
                ></v-autocomplete>
                <div v-if="Object.keys(form.selectedProductSX).length">
                  <v-card outlined class="pa-0">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Tên sản phẩm
                        </v-list-item-subtitle>
                        <div class="text-subtitle-2">
                          {{ form.selectedProductSX.ten }}     
                        </div>          
                        <v-list-item-subtitle>
                          Mã sản phẩm
                        </v-list-item-subtitle>
                        <v-list-item-title>
                        <div class="text-subtitle-2">
                          {{ form.selectedProductSX.ma }}
                        </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-column mx-3">
                <div class="label-form">Chọn danh sách sản phẩm gộp</div>
                <v-autocomplete
                  v-model="form.selectedProductKD"
                  placeholder="Chọn sản phẩm gộp"
                  :items="listToMerge"
                  chips
                  deletable-chips
                  outlined
                  :item-text="getText"
                  item-value="id"
                  dense
                  multiple
                  return-object
                  hide-details="auto"
                  :menu-props="{top: true}"
                  :rules="nameProductKDRules"
                ></v-autocomplete>
                <div max-height="60%" v-if="Object.keys(form.selectedProductKD).length">
                  <v-card outlined>
                    <v-card-subtitle class="px-3 py-2">
                      Chi tiết danh sách
                    </v-card-subtitle>
                    <v-list dense max-height="150" class="overflow-auto pa-0">
                      <v-list-item v-for="item in form.selectedProductKD" :key="item.id">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.ten }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeFormMerge" text class="mr-2">Đóng</v-btn>
        <v-btn @click="mergeProducts" color="primary" dark>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mergeProduct } from "@/api/sanpham";
import * as notify from "@/utils/notify-show";
export default {
  props: {
    title: String,
    listProductSX: Array,
    listProductKD: Array
  },
  data() {
    return {
      listToMerge: [],
      show: false,
      isLoading: false,
      form: {
        selectedProductSX: {},
        selectedProductKD: [],
      },
      nameProductSXRules: [(v) => !!v || "Tên sản phẩm chính không thể bỏ trống"],
      nameProductKDRules: [(v) => !!v || "Tên sản phẩm gộp không thể bỏ trống"],
    };
  },
  watch: {
    form: {
      handler(oldValue, newValue) {
        if (this.listProductSX.includes(this.form.selectedProductSX)) {
          let selectedId = this.form.selectedProductSX.id;
          this.listToMerge = this.listProductSX.filter(product => product.id !== selectedId);
        }
      },
      deep: true
    }
  },
  methods: {
    getText(item) {
      return `${item.ma} - ${item.ten}`;
    },
    showFormMerge() {
      this.listToMerge = Object.assign([], this.listProductSX);
      this.show = true;
      this.form = {
        selectedProductSX: {},
        selectedProductKD: [],
      };
    },
    closeFormMerge() {
      this.show = false;
    },
    createPayLoad() {
      let productKDIds = this.form.selectedProductKD.map(product => product.id);
      return {
        target_id: this.form.selectedProductSX.id,
        change_needed_ids: productKDIds,
      };
    },
    async mergeProducts() {
      this.isLoading = true;
      const payload = this.createPayLoad();
      try {
        const res = await mergeProduct(payload);
        if (!res.error) {
          this.isLoading = false;
          this.$emit('on-done');
          notify.info("Gộp sản phẩm thành công");
          this.show=false;
        }
      } catch (error) {
        notify.error("Đã có lỗi xảy ra khi gộp sản phẩm\n" + error.message);
        this.isLoading = false;
        this.show=false;
      }
    },
  },
};
</script>
