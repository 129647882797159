var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageListLayout',{attrs:{"icon":"mdi-archive","title":"Sản phẩm","subTitle":"Danh sách sản phẩm","useAdd":"","titleAdd":"Tạo mới sản phẩm"},on:{"add":_vm.createItem}},[_c('template',{slot:"side-right"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":""},on:{"click":_vm.onMergeProduct}},on),[_c('v-icon',[_vm._v("mdi-call-merge")])],1)]}}])},[_c('span',[_vm._v("Gộp sản phẩm")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"loading":_vm.loadingImport,"icon":""},on:{"click":_vm.importData}},on),[_c('v-icon',[_vm._v(" mdi-file-import-outline ")])],1)]}}])},[_c('span',[_vm._v("Nhập dữ liệu")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"loading":_vm.loadingExport,"icon":""},on:{"click":_vm.exportExcel}},on),[_c('v-icon',[_vm._v(" mdi-file-export-outline ")])],1)]}}])},[_c('span',[_vm._v("Kết xuất dữ liệu")])])],1)]),_c('template',{slot:"filter"},[_c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm","clearable":"","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formSearch.search),callback:function ($$v) {_vm.$set(_vm.formSearch, "search", $$v)},expression:"formSearch.search"}})],1),_c('div',{staticClass:"mt-4"},[_c('v-autocomplete',{attrs:{"items":_vm.customersList,"label":"Khách hàng","outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"style_auto_complate",attrs:{"title":data.item.name},domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]]}}]),model:{value:(_vm.formSearch.customer_id),callback:function ($$v) {_vm.$set(_vm.formSearch, "customer_id", $$v)},expression:"formSearch.customer_id"}})],1),_c('div',{staticClass:"mt-4"},[_c('v-autocomplete',{attrs:{"items":_vm.productTypesData,"label":"Loại sản phẩm","item-text":"ten","item-value":"id","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.formSearch.loai_chat_thai_id),callback:function ($$v) {_vm.$set(_vm.formSearch, "loai_chat_thai_id", $$v)},expression:"formSearch.loai_chat_thai_id"}})],1)]),_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ...","pageCount":_vm.pageCount,"classPaging":"pt-2"},on:{"update:page":function($event){_vm.page=$event},"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('img',{attrs:{"height":"50px","width":"50px","src":item.image && _vm.getImageUrl(item.image.link)}}):_vm._e()]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.parent ? item.parent.order + "-" + item.order : item.order))])]}},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatnumber(item.unit_price))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Cập nhật ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa Sản phẩm ")],1)],1)],1)],1)]}}],null,true)}),_c('create-edit',{ref:"form",attrs:{"productTypesList":_vm.productTypesData,"customersList":_vm.customersList},on:{"on-done":_vm.getAllList}}),_c('DialogMergeProduct',{key:_vm.newKey,ref:"formMerge",attrs:{"title":"GỘP SẢN PHẨM","listProductKD":_vm.listProductKD,"listProductSX":_vm.listProduct},on:{"on-done":_vm.refreshData}}),_c('ImportFile',{ref:"formImport",on:{"on-done":_vm.openImportPopup}}),_c('import-popup',{ref:"popUp",on:{"onClose":_vm.getAllList}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }