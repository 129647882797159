<template>
  <v-dialog v-model="show" persistent width="800">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT SẢN PHẨM" : "THÊM SẢN PHẨM"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col xs="8" sm="8" md="8">
              <div class="label-form">Tên sản phẩm</div>
              <v-text-field
                placeholder="Tên sản phẩm"
                outlined
                dense
                prepend-inner-icon="mdi-account"
                v-model="form.ten"
                :rules="nameProductRules"
              ></v-text-field>
            </v-col>
            <v-col xs="4" sm="4" md="4">
              <div class="label-form">Mã sản phẩm</div>
              <v-text-field
                placeholder="Mã sản phẩm"
                outlined
                dense
                :disabled=!showEditMaSanPham
                prepend-inner-icon="mdi-account"
                v-model="form.ma"
                :rules="codeProductRules"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <div class="label-form">Thuộc khách hàng</div>
              <v-autocomplete
                prepend-inner-icon="mdi-folder-account"
                v-model="form.customer_id"
                :items="customersList"
                outlined
                dense
                item-text="name"
                item-value="id"
              >
              </v-autocomplete>
            </v-col> -->
            <v-col xs="4" sm="4" md="4">
              <div class="label-form">Đơn vị tính</div>
              <v-text-field
                placeholder="Đơn vị tính"
                outlined
                dense
                prepend-inner-icon="mdi-account"
                v-model="form.don_vi_tinh"
                :rules="unitProductRules"
              ></v-text-field>
            </v-col>
            <v-col xs="4" sm="4" md="4">
              <div class="label-form">Loại sản phẩm</div>
              <v-select
                prepend-inner-icon="mdi-image-area"
                v-model="form.loai_chat_thai_id"
                :items="productTypesList"
                outlined
                dense
                item-text="ten"
                item-value="id"
                :rules="request_typeRules"
              >
              </v-select>
            </v-col>
            <v-col xs="4" sm="4" md="4">
              <div class="label-form">Quy đổi sang kg</div>
              <v-text-field
                placeholder="Quy đổi sang kg"
                outlined
                dense
                prepend-inner-icon="mdi-account"
                v-model="form.quy_doi"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col xs="12" sm="12" md="12">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.mo_ta"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
                height="100px"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { UNITS } from "@/constants/yeucaubaogia";
// import { addProducts, editProducts } from "@/api/sanpham";
import chatThaiAPI from "@/api/sx.chatthai.api";
import JsonToFromData from "@/utils/JsonToFormData.js";
export default {
  props: ["productTypesList", "customersList"],
  data: () => ({
    UNITS,
    showEditMaSanPham: false,
    show: false,
    edit: false,
    btnLoading: false,
    form: {
      ma: null,
      ten: null,
      type: null,
      loai_chat_thai_id: null,
      quy_doi: null,
      mo_ta: null,
    },
    codeProductRules: [(v) => !!v || "Mã sản phẩm không thể bỏ trống"],
    nameProductRules: [(v) => !!v || "Tên sản phẩm không thể bỏ trống"],
    unitProductRules: [(v) => !!v || "Đơn giá không thể bỏ trống"],
    request_typeRules: [(v) => !!v || "Loại không thể bỏ trống"],
  }),
  computed: {},
  methods: {
    showFormAdd(form = {}) {
      this.show = true;
      this.edit = false;
      this.showEditMaSanPham = true;
      this.form = { ...form };
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.showEditMaSanPham = false;
      this.form = { ...data };
    },
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
    async addItem() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          data.type = data.type && parseInt(data.type);
          const res = await chatThaiAPI.create(data);
          this.show = false;
          this.btnLoading = false;
          if (res?.isDone) {
            this.$emit("on-done");
          }
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          data.type = data.type && parseInt(data.type);
          await chatThaiAPI.update(data.id, data);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
