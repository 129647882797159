<template>
  <PageListLayout
    icon="mdi-archive"
    title="Sản phẩm"
    subTitle="Danh sách sản phẩm"
    useAdd
    titleAdd="Tạo mới sản phẩm"
    @add="createItem"
  >
    <template slot="side-right">
      <div class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-1" icon v-on="on" @click="onMergeProduct">
              <v-icon>mdi-call-merge</v-icon>
            </v-btn>
          </template>
          <span>Gộp sản phẩm</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :loading="loadingImport"
              class="mr-1"
              icon
              v-on="on"
              @click="importData"
            >
              <v-icon> mdi-file-import-outline </v-icon>
              <!-- <input
                type="file"
                ref="fileInput"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style="display: none"
                @change="importExcel"
              /> -->
            </v-btn>
          </template>
          <span>Nhập dữ liệu</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :loading="loadingExport"
              class="mr-1"
              icon
              v-on="on"
              @click="exportExcel"
            >
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
          </template>
          <span>Kết xuất dữ liệu</span>
        </v-tooltip>
      </div>
    </template>
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="formSearch.search"
          label="Tìm kiếm"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.customer_id"
          :items="customersList"
          label="Khách hàng"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          clearable
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.name"
                  v-html="data.item.name"
                >
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.loai_chat_thai_id"
          :items="productTypesData"
          label="Loại sản phẩm"
          item-text="ten"
          item-value="id"
          outlined
          dense
          hide-details
          clearable
        ></v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <img
          height="50px"
          width="50px"
          :src="item.image && getImageUrl(item.image.link)"
          v-if="item.image"
        />
      </template>
      <template v-slot:[`item.order`]="{ item }">
        <span>{{
          item.parent ? item.parent.order + "-" + item.order : item.order
        }}</span>
      </template>
      <template v-slot:[`item.unit_price`]="{ item }">
        {{ formatnumber(item.unit_price) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item disabled @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa Sản phẩm
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <create-edit
      ref="form"
      @on-done="getAllList"
      :productTypesList="productTypesData"
      :customersList="customersList"
    >
    </create-edit>
    <!-- <MergeProduct
      ref="formMerge"
      :listProduct="listProduct"
      @on-done="getAllList"
    ></MergeProduct> -->
    <DialogMergeProduct
      ref="formMerge"
      title="GỘP SẢN PHẨM"
      :listProductKD="listProductKD"
      :listProductSX="listProduct"
      @on-done="refreshData"
      :key="newKey"
    >
    </DialogMergeProduct>
    <ImportFile ref="formImport" @on-done="openImportPopup"> </ImportFile>
    <import-popup ref="popUp" @onClose="getAllList"> </import-popup>
  </PageListLayout>
</template>
<script>
import {
  getUnmergedProducts,
  getProducts,
  deletedProducts,
  productExport,
  productImport,
} from "@/api/sanpham";
// import { getAllProductTypes } from "@/api/loaisanpham";
//
import chatThaiAPI from "@/api/sx.chatthai.api";
import loaiChatThaiAPI from "@/api/sx.loaichatthai.api";

import CreateEdit from "./create-edit";
import { debounce } from "lodash";
import KHACHHANG from "@/api/khachhang";
import { numberFormat } from "@/utils/moneyFormat";
import { saveAs } from "file-saver";
import DialogMergeProduct from "./dialog-merge-product.vue";
import MergeProduct from "./merge-product";
import ImportPopup from "./import-popup.vue";
import ImportFile from "./import-file";
export default {
  components: { CreateEdit, DialogMergeProduct, ImportPopup, ImportFile },
  data() {
    return {
      newKey: 1,
      loadingImport: false,
      selectedFile: null,
      loadingExport: false,
      formSearch: {},
      page: 1,
      listProduct: [],
      listProductKD: [],
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      productTypesData: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      customersList: [],
      headers: [
        // { text: "STT", width: "100", sortable: false },
        // { text: "Icon", value: "icon", sortable: false },
        {
          text: "Mã sản phẩm",
          align: "start",
          sortable: false,
          value: "ma",
        },
        { text: "Tên sản phẩm", value: "ten" },
        // { text: "Thuộc khách hàng", value: "customers.name" },
        { text: "Loại sản phẩm", value: "loai_chat_thai.ten" },
        { text: "Đơn vị tính", value: "don_vi_tinh" },
        { text: "Quy đổi sang kg", value: "quy_doi" },
        // { text: "Hình ảnh", value: "image" },
        // { text: "Đơn giá mới", value: "new_price" },
        // { text: "Ghi chú", value: "description" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.getAllListProduct();
    this.getAllList();
    this.getProductTypesData();
    this.getCustomersList();
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function(val) {
        this.page = 1;
        this.getAllList();
      }, 300),
    },
  },
  methods: {
    refreshData() {
      this.newKey += 1;
      this.getAllList();
      this.getAllListProduct();
    },
    async getAllListProduct() {
      this.loading = true;
      let data = await chatThaiAPI.list();
      this.listProduct = data.data;
    },
    async getAllList() {
      this.loading = true;
      let data = await chatThaiAPI.list({
        page: this.page,
        per_page: this.itemsPerPage,
        is_paginate: true,
        ...this.formSearch,
      });
      this.loading = false;
      data = data.data;
      this.tableData = data.data;
      this.pageCount = data.last_page;
    },
    async getCustomersList() {
      let res = await KHACHHANG.getAllCustomers();
      this.customersList = res.data;
    },
    async getProductTypesData() {
      this.loading = true;
      let res = await loaiChatThaiAPI.list();
      this.loading = false;
      this.productTypesData = res.data;
    },
    onMergeProduct() {
      this.$refs.formMerge.showFormMerge();
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa sản phẩm",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa sản phẩm " +
          "<strong>" +
          item.ten +
          " ?" +
          "</strong>",
        action: () => chatThaiAPI.destroy(item.id),
        onDone: this.getAllList,
      });
    },
    createItem() {
      this.$refs.form.showFormAdd();
    },
    getProductTypesName(id) {
      return this.productTypesData.find((item) => item.id === id)
        ? this.productTypesData.find((item) => item.id === id).name
        : null;
    },
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
    formatnumber(number) {
      return numberFormat(number);
    },

    async exportExcel() {
      try {
        this.loadingExport = true;
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
        let newdate = day + "/" + month + "/" + year;
        const res = await chatThaiAPI.productExport();
        if (res && res.isDone) {
          saveAs(new Blob([res.data]), "san_pham" + "-" + newdate + ".xlsx");
        }
      } finally {
        this.loadingExport = false;
      }
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    importData() {
      this.$refs.formImport.showFormImportExcel();
    },
    openImportPopup(importResponseData) {
      console.log(importResponseData, "log return data from import");
      this.$refs.popUp.openImportDialog(importResponseData);
    },
  },
};
</script>
<style scoped>
.style_auto_complate {
  max-width: 200px;
}
</style>
